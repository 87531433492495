import { Injectable, inject } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router'
import { EnvironmentService } from '../../../environments/environment.service'
import { AreasService } from '../../../environments/areas.service'
import { AREA } from '../../constants/areas.constants'
import { ITab } from '../../shared/models/tab'
import { UserPermissionConfig } from './roles/roles-config'

@Injectable({ providedIn: 'root' })
export class GuardVerification {
	allowedTabs: Map<string, UserPermissionConfig[]> = new Map()
	constructor(
		private envSer: EnvironmentService,
		apiAreasService: AreasService,
	) {
		const areas = apiAreasService.getAvailableAreas()

		areas.flatMap((area) => {
			if (area.code == AREA.AUTH) return []

			// Check permissions from all subareas and map the allowed ones to the template
			const subAreas = area.subAreas?.map((suba) => {
				return {
					name: suba.name,
					route: suba.route,
					permission: suba.permission,
				} as ITab
			})
			area.subAreas?.forEach((area) => {
				this.allowedTabs.set(area.route || '', area.permission || [])
			})
			return []
		})
	}

	public checkUserPermissions(childPath: string): boolean {
		if (this.allowedTabs.has(childPath)) {
			const permissions = this.allowedTabs.get(childPath) || []
			return this.envSer.checkUserContainsSomePermissions(permissions)
		}
		return false
	}
}

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	if (inject(EnvironmentService).getRefreshToken() === null) {
		inject(Router).navigate(['auth/login'])
		return false
	}
	return true
	if (inject(GuardVerification).checkUserPermissions(state.url) === false) {
		inject(Router).navigate(['auth/login'])
		return false
	}
	return true
}

//checks if user is logged in (for the auth)
export const LoginMadeAuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	if (inject(EnvironmentService).getRefreshToken() !== null) {
		inject(Router).navigate([''])
		return false
	}
	return true
}

export const BackofficeAuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	var router = inject(Router)
	const subArea = state.url.split('/')[2].trim()
	const apiAreasService = inject(AreasService)

	if (
		apiAreasService.recursiveFindNestedArea(
			subArea,
			apiAreasService.getAvailableAreas(),
		) === undefined
	) {
		//redirect to unauthorized page
		router.navigate(['/unauthorized'], { replaceUrl: true })
		return false
	}
	return true
}

export const HomeAuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	if (inject(EnvironmentService).getRefreshToken() === null) {
		inject(Router).navigate([''])
		return false
	}
	return true
}

export const SettingsAuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	if (inject(EnvironmentService).getRefreshToken() === null) {
		inject(Router).navigate(['/unauthorized'])
		return false
	}
	return true
}
