import { Injectable } from '@angular/core'
import * as L from 'leaflet'
import 'leaflet-routing-machine'
import { StopsModel } from '../models/stop'

export interface waypoint {
	lat: number
	lng: number
}

@Injectable()
export class LeafletRoutingService {
	mapNavigate(vehicleType: string, rawStops: StopsModel[]) {
		const stops = this.convertToWaypoint(rawStops)

		return L.Routing.control({
			waypoints: stops.map((stop) => L.latLng(stop.lat, stop.lng)),
			routeWhileDragging: true,
			show: true,
		})
	}

	convertToWaypoint(stops: StopsModel[]): waypoint[] {
		return stops.map((stop) => {
			const coordinates = stop.coordinates.split(',')
			return {
				lat: parseFloat(coordinates[0]),
				lng: parseFloat(coordinates[1]),
			}
		})
	}
}
